.alert-container{
    width: 300px;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid rgb(170, 167, 167);
}

.alert-btn{
    border: none;
    outline: none;
    padding: 10px 20px 10px;
    margin: 20px 0;
}