body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: 30px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-responsive {
  --kt-scrollbar-height: 10px;
}
.table-hover tbody:hover table-div:hover 
    {  
   background-color: #B0B0B0 !important;  
}

.table td, .table th{
  border-color: #B0B0B0;
}
tr:last-child {
  border-bottom: 1px solid #B0B0B0;
}
/* tr:nth-child(2n+2){background-color: #E7E9EB;}
tr:nth-child(2n+1){background-color: #fff;}
/* 
tr:nth-child(2n+2){background-color: #E7E9EB;}
tr:nth-child(2n+1){background-color: #fff;} */
/* thead, tr{background-color: #ddd} */
/* table tr td {
  padding-left:5px
}

table thead th tr{
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
} 

table thead th tr{
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}   */

/* .table-bordered {
  
    border: 1px solid #DEDEDE !important;
 
}
th,
  td {
    border: 1px solid #DEDEDE !important;
  } */


#datepickerdob > div > div > div > input  {
  /* border: 1px solid red; */
  font-size: medium;
}

#kt_app_sidebar {
    overflow-x: hidden;
    overflow-y: visible;
    height: 100%;
}
